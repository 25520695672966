<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text">{{$t('user_data_deletion_instructions.header')}}</h4>
    </div>
    <h6>{{ $t("user_data_deletion_instructions.facebook_data_deletion_instructions")}}</h6>
     <p>{{ $t("user_data_deletion_instructions.for_user_who_have_created_an_account_with_bluuu")}}</p>
    <div class="ml-5">
        <p>{{ $t("user_data_deletion_instructions.go_to_your_facebook_account")}}</p>
         <p>{{ $t("user_data_deletion_instructions.look_for_apps_and_websites")}}</p>
          <p>{{ $t("user_data_deletion_instructions.search_and_click")}}</p>
           <p>{{ $t("user_data_deletion_instructions.scroll_and_click_remove")}}</p>
    </div>
      <h6>{{ $t("user_data_deletion_instructions.bluuu_data_deletion_instructions")}}</h6>
     <p>{{ $t("user_data_deletion_instructions.if_you_would_like_to_permanently")}} <a href="">{{ $t("user_data_deletion_instructions.info_bluuu")}}</a> {{ $t("user_data_deletion_instructions.continue")}}</p>
    <div class="ml-5">
        <p>{{ $t("user_data_deletion_instructions.phone_number_verified_in_your_account")}}</p>
        
          <p>{{ $t("user_data_deletion_instructions.email_address")}}</p>
       
    </div>
        <p>{{ $t("user_data_deletion_instructions.warning")}}</p>

  </b-container>
</template>

<script>
// import PrivacyPolicyContent from '../components/Modal/PrivacyPolicyContent'
export default {
  // components: { PrivacyPolicyContent }
}
</script>
